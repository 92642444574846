/**
 * StateStore内のEntityにアクセスするためのIDを決めておく
 */
export const STORE_ENTITY_ID = {
    HEAD_UTIL_STATE: 'HeadUtilSpaceState',
    USER_STATE: 'AppUserState',
    AGM_CAMERA_STATE: 'AgmCameraState',
    AGM_USER_MAP_STATE: 'AgmUserMapState',
    AGM_ADMIN_MAP_STATE: 'AgmAdminMapState',
    AGM_CURRENT_LOCATION_STATE: 'AgmCurrentState',
    AGM_ROUTE_TARGET_STATE: 'AgmRouteTargetState',
    AGM_NAVIGATION_STATE: 'AgmNavigationState'
};
