/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:375d521d-b574-4ed1-9063-5e11db9101d7",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_jNXT8kcuD",
    "aws_user_pools_web_client_id": "6jg2mf0afq9q35unr5bqp3q3m0",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://5xsxmlomqndcdksotrw7zuutua.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-33e7kfylo5giloc57x6zj6gzdi",
    "aws_user_files_s3_bucket": "mcnd967872702044ddc8e379991c4688b86175627-kiaidev",
    "aws_user_files_s3_bucket_region": "ap-northeast-1",
    "aws_content_delivery_bucket": "20210329180112-hostingbucket-kiaidev",
    "aws_content_delivery_bucket_region": "ap-northeast-1",
    "aws_content_delivery_url": "http://20210329180112-hostingbucket-kiaidev.s3-website-ap-northeast-1.amazonaws.com"
};


export default awsmobile;
