import { Pipe, PipeTransform } from '@angular/core';
import { hasThumbnail } from 'src/app/interfaces/common';

@Pipe({name: 'thumbnail'})
export class ThumbnailPipe implements PipeTransform {
  transform(value: hasThumbnail, showDefault: boolean = true): string {
    return value.thumbnail ? 
      `${value.thumbnail}` : 
      (showDefault ? 'assets/default-thumbnail.jpg' : null);
  }
}