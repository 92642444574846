import {Injectable} from '@angular/core';
import {AlertController, LoadingController, MenuController, ModalController, Platform, ToastController} from '@ionic/angular';
import {NavigationExtras, Params, Router} from '@angular/router';
import {LoadingOptions} from '@ionic/core';
import {AppMessageConfig} from '../../app.message.config';
import {NgxCsvParser} from 'ngx-csv-parser';
import encoding from 'encoding-japanese';
import csv from 'csvtojson';
import {ErrorService} from './error.service';
import {ErrorState} from '../../interfaces/system/error';

@Injectable({
    providedIn: 'root'
})
/**
 * 汎用的なメソッドをまとめたサービス
 * リダイレクトやダイアログの表示、バックボタンの制御など
 */
export class SharedService {
    encoding = encoding;

    constructor(private toast: ToastController,
                private alert: AlertController,
                private modal: ModalController,
                private loading: LoadingController,
                private router: Router,
                private platform: Platform,
                private menuCtrl: MenuController,
                private csvparser: NgxCsvParser,
                private error: ErrorService) {
    }

    /**
     * エラーを流し込む
     * @param errorState
     */
    onError(errorState: ErrorState) {
        this.error.onError(errorState);
    }

    async changeDisableStateMenu(state: boolean) {
        await this.menuCtrl.enable(!state);
    }

    // プロミス版SetTimeout(並列処理のDelay用)
    /**
     * 非同期的にディレイをかける
     * Promise.allなどで分散処理する際のディレイとして使用など
     * @param duration
     */
    async delayPromise(duration) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve();
            }, duration);
        });
    }

    // toast
    /**
     * トーストを下部に表示する
     * @param toastmessage
     * @param killtime
     */
    async dispToast(toastmessage: string, killtime = 3000) {
        const toast = await this.toast.create({message: toastmessage, duration: killtime});
        await toast.present();
    }

    // alert表示
    /**
     * アラートを表示する
     * @param headerText
     * @param dispText
     * @param buttonText
     */
    async openAlert(headerText: string, dispText: string, buttonText: string) {
        const alert = await this.alert.create({
            header: headerText,
            message: dispText,
            buttons: [buttonText],
            mode: 'ios',
            backdropDismiss: false
        });
        await alert.present();
        return alert;
    }

    /**
     * 確認が取れるまで処理をブロックするアラート
     * @param title
     * @param message
     * @param TrueButtonText
     * @param danger
     */
    async openConfirmAlert(title: string, message: string, TrueButtonText: string, danger = false): Promise<boolean> {
        let resolveFunction: (confirm: boolean) => void;
        const promise = new Promise<boolean>(resolve => {
            resolveFunction = resolve;
        });
        const alert = await this.alert.create({
            header: title,
            message,
            backdropDismiss: false,
            mode: 'ios',
            buttons: [{
                text: AppMessageConfig.SystemMessage.CONFIRM_CANCEL_TEXT,
                handler: () => resolveFunction(false)
            }, {
                text: TrueButtonText,
                handler: () => resolveFunction(true),
                cssClass: danger ? 'alert-NegativeButton' : '',
            }]
        });
        await alert.present();
        return promise;
    }

    // ローディングスクリーンの呼び出し
    /**
     * ロード画面呼び出し
     * @param loadOption
     */
    async openLoading(loadOption: LoadingOptions) {
        const loading = await this.loading.create(loadOption);
        await loading.present();
        return loading;
    }

    /**
     * モーダルの使用
     * @param ModalComponent
     * @param props
     */
    async presentModal(ModalComponent: any, props = {}) {
        const modal = await this.modal.create({
            component: ModalComponent,
            cssClass: ['half-modal'],
            swipeToClose: true,
            backdropDismiss: true,
            mode: 'ios',
            componentProps: props,
            presentingElement: document.getElementById('ion-router-outlet-content')
        });
        modal.present();
        return modal;
    }

    /**
     * 別のページにリダイレクトを行う
     * @param redirectPath
     * @param config
     */
    async redirectTo(redirectPath: string, config: NavigationExtras = {}) {
        await this.router.navigate([redirectPath], config);
    }

    /**
     * リダイレクト時にクエリを使用する
     * @param redirectPath
     * @param query
     */
    redirectToWithQuery(redirectPath: string, query: Params) {
        this.router.navigate([redirectPath, query]);
    }

    /**
     * Safari対策でwindow.openが駄目だったらLocationHrefにする
     * @param url
     */
    redirectOuterPage(url: string) {
        if (!window.open(url, '_blank')) {
            window.location.href = url;
        }
    }

    /**
     * 引数で渡されたPathが現在のパスに含まれているかチェックする
     * @param path
     */
    isCheckCurrentUrlPath(path: string): boolean {
        return this.router.url.includes(path);
    }


    // CSV関係
    /**
     * Shift-JISの文字をUTF-8に変換する
     * @param file
     */
    private convertJistoUtf8(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                // @ts-ignore
                const codes = new Uint8Array(e.target.result);
                const encode = this.encoding.detect(codes);

                // Convert encoding to unicode
                const unicodeString = this.encoding.convert(codes, {to: 'UNICODE', from: encode, type: 'string'});
                // console.log(unicodeString);
                resolve(unicodeString as string);
            };
            reader.onerror = (ev) => {
                reject(ev);
            };

            reader.readAsArrayBuffer(file);
        });
    }

    /**
     * csvファイルをテキストに変換する
     * @param fileEvent
     */
    async convertCsvFileToText(fileEvent: any): Promise<{ csv: object[], csvName: string } | undefined> {
        // 複数のファイルが読み込まれていたら
        if (fileEvent.target.files.length > 1) {
            // this.dispToast('ファイルは1つだけ読み込んで下さい。');
            return;
        }
        // ファイルの拡張子がcsvじゃなかったら
        // event.target['files'][0].name.match(/\.(csv|png)$/i)
        if (!this.csvparser.isCSVFile(fileEvent.target.files[0])) {
            // await this.openAlert('警告！', 'CSVファイルしか読み込めません。', '分かりました');
            return;
        }

        // csvファイルに含まれる日本語をunicodeへ変換
        const csvstring = await this.convertJistoUtf8(fileEvent.target.files[0]);
        // csv文字列からjsonを生成
        const csvjson = await csv({output: 'json'}).fromString(csvstring);
        // console.log(csvjson);
        return {csv: csvjson, csvName: fileEvent.target.files[0].name as string};
    }
}
