import {Injectable} from '@angular/core';
import {SideMenuContentObject} from '../../interfaces/sidemenu';

@Injectable({
  providedIn: 'root'
})
export class SidemenuService {
  /**
   * 一般ユーザ用サイドメニュー
   */
  userSideMenu: SideMenuContentObject[] = [
    {
      title: 'ホーム',
      url: '/',
      icon: 'home',
      color: 'primary'
    },
    // {
    //   title: 'いいね',
    //   url: '/likelist',
    //   icon: 'heart',
    //   color: 'primary'
    // },
    {
      title: 'リクエスト',
      url: '/requestlist',
      icon: 'person',
      color: 'primary'
    },
    // {
    //   title: '設定',
    //   url: '/setting',
    //   icon: 'settings',
    //   color: 'primary'
    // },
    {
      title: 'Privacy Policy',
      url: '/privacypolicy',
      href: 'https://peraichi.com/landing_pages/view/privacyno',
      icon: 'shield-checkmark',
      // color: 'primary'
    },
    {
      title: 'About us',
      url: '/aboutus',
      icon: 'information-circle',
      href: 'https://new-ordinary.jp/'
      // color: 'primary'
    }
  ];

  /**
   * 管理者用サイドメニュー
   */
  adminSideMenu: SideMenuContentObject[] = [
    {
      title: '管理者ホーム',
      url: '/admin',
      icon: 'home',
      color: 'primary'
    }
  ];

  constructor() {
  }

  isAdminPath(path: string) {
    /**
     * TODO adminのpathを変えたら変更する(TestでAdminのpathを確認してもいいかも)
     */
    return path.includes('/admin');
  }
}
