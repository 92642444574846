import {Injectable} from '@angular/core';
import {UiStore} from './ui.store';
import {HeadUtilState} from '../../interfaces/uistate';
import {UiQuery} from './ui.query';
import {AgmCameraState, AgmCurrentLocationState, AgmLocationState, AgmMapState, AgmNavigationState} from '../../interfaces/agm/agm-state';
import {STORE_ENTITY_ID} from '../state.config';

@Injectable({providedIn: 'root'})
export class UiService {
    constructor(private uiStore: UiStore,
                private uiQuery: UiQuery) {
        this.initUiState();
    }

    private initUiState() {
        // Uiの状態を初期化
        this.uiStore.add({
            id: STORE_ENTITY_ID.HEAD_UTIL_STATE,
            headUtilSpaceState: {
                uiState: 'movableDistanceWalk',
                uiMoveDistanceTimeState: 1,
                uiSearchBoxState: '',
                uiInformation: {
                    iconName: 'walk'
                }
            }
        });

        // AGM関連
        // GoogleMapのカメラを初期化
        this.uiStore.add({
            id: STORE_ENTITY_ID.AGM_CAMERA_STATE,
            agmCameraState: {
                lat: 35.4122,
                lng: 139.4130,
                zoom: 6,
            },
            agmCurrentLocationState: {
                lat: 32.4122,
                lng: 139.4130,
                radius: 0
            }
        });
        // 行き先候補地の初期化
        this.uiStore.add({
            id: STORE_ENTITY_ID.AGM_ROUTE_TARGET_STATE,
            agmRouteTargetState: []
        });
        // 初期状態のマップ状態(USER)
        this.uiStore.add({
            id: STORE_ENTITY_ID.AGM_USER_MAP_STATE,
            agmUserMapState: {
                userMapState: 'Normal',
            }
        });
        // (Admin)
        this.uiStore.add({
            id: STORE_ENTITY_ID.AGM_ADMIN_MAP_STATE,
            agmAdminMapState: {
                adminMapState: 'Normal',
            }
        });
    }

    /**
     * マップ画面上のUIの状態を更新
     * @param state
     */
    updateHeadUtilSpaceState(state: HeadUtilState) {
        this.uiStore.upsert(STORE_ENTITY_ID.HEAD_UTIL_STATE, {headUtilSpaceState: state});
    }

    /**
     * Agmのカメラ位置を更新
     * @param state
     */
    updateAgmCameraState(state: AgmCameraState) {
        this.uiStore.upsert(STORE_ENTITY_ID.AGM_CAMERA_STATE, {agmCameraState: state});
    }

    /**
     * ユーザのマップ状態を更新
     * @param state
     */
    updateUserAgmMapState(state: Pick<AgmMapState, 'userMapState'>) {
        this.uiStore.upsert(STORE_ENTITY_ID.AGM_USER_MAP_STATE, {agmUserMapState: state});
    }

    /**
     * 管理者のマップ状態を更新
     * @param state
     */
    updateAdminAgmMapState(state: Pick<AgmMapState, 'adminMapState'>) {
        this.uiStore.upsert(STORE_ENTITY_ID.AGM_ADMIN_MAP_STATE, {agmAdminMapState: state});
    }

    /**
     * ユーザの現在位置を更新
     * @param state
     */
    updateUserCurrentLocationState(state: AgmCurrentLocationState) {
        this.uiStore.upsert(STORE_ENTITY_ID.AGM_CURRENT_LOCATION_STATE, {agmCurrentLocationState: state});
    }

    /**
     * 行き先候補地を更新する
     * @param state
     */
    updateUserRouteTargetState(state: AgmLocationState[]) {
        this.uiStore.upsert(STORE_ENTITY_ID.AGM_ROUTE_TARGET_STATE, {agmRouteTargetState: state});
    }

    /**
     * ナビのルートの状態を更新する
     * @param state
     */
    updateAgmNavigationState(state: AgmNavigationState) {
        this.uiStore.upsert(STORE_ENTITY_ID.AGM_NAVIGATION_STATE, {agmNavigationState: state});
    }
}
