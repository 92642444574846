import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {UserState, UserStore} from './user.store';
import {STORE_ENTITY_ID} from '../state.config';
import {map, tap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class UserQuery extends QueryEntity<UserState> {
    isUserAuthState$ = this.selectEntity(STORE_ENTITY_ID.USER_STATE).pipe(map(state => state?.isLogin), tap(data => console.log('isAuthState:', data)));
    isUserQuestionState$ = this.selectEntity(STORE_ENTITY_ID.USER_STATE).pipe(map(state => state?.isUserFavoriteQuestion), tap(data => console.log('isQuestion:', data)));
    userCognitoInfo$ = this.selectEntity(STORE_ENTITY_ID.USER_STATE).pipe(map(state => state?.cognitoUser));
    userState$ = this.selectEntity(STORE_ENTITY_ID.USER_STATE);

    constructor(protected store: UserStore) {
        super(store);
    }

    getUserState() {
        return this.getEntity(STORE_ENTITY_ID.USER_STATE);
    }

}
