import {Component, Input, OnInit, Output} from '@angular/core';
import {SideMenuContentObject} from '../../../interfaces/sidemenu';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss'],
})
export class SidemenuComponent implements OnInit {

  selectedIndex: number;
  @Input() appPages: SideMenuContentObject[];

  constructor() {
  }

  ngOnInit() {
  }

}
