import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {SharedService} from '../services/shared.service';
import {map} from 'rxjs/operators';

/**
 * 管理者画面へのアクセスを制限する。
 */
@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor(
      private authService: AuthService,
      private sharedService: SharedService
  ) {
  }

  canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.isSignIn().pipe(
        map((user) => {
          if (user) {
            const roleArray = user.signInUserSession.idToken.payload['cognito:groups'];
            const roleList = this.authService.checkRole(roleArray);
            // 管理者画面へは管理者かイベントユーザのみアクセス可能
            if (roleList.length > 0) {
              return true;
            } else {
              return this.redirectApp();
            }
          } else {
            return this.redirectApp();
          }
        }));
  }

  /**
   * リダィレクト時の処理をまとめる
   * @private
   */
  private redirectApp() {
    this.sharedService.redirectTo('/auth/login');
    return false;
  }

}
