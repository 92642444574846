import {Component, OnDestroy, OnInit} from '@angular/core';

import {MenuController, Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {AuthService} from './core/services/auth.service';
import {NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {SidemenuService} from './core/services/sidemenu.service';
import {SideMenuContentObject} from './interfaces/sidemenu';
import {UiService} from './state/uiState';
import {UserQuery} from './state/userState';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    selectedIndex = 0;
    sideMenu: SideMenuContentObject[];
    checkRouterSubscription: Subscription;
    isAuthState$ = this.userQuery.isUserAuthState$;
    userState$ = this.userQuery.userState$;
    userInfo$ = this.authService.getCognitoUserInfo();


    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private authService: AuthService,
        private menuController: MenuController,
        private router: Router,
        private sideMenuService: SidemenuService,
        private uiState: UiService,
        private userQuery: UserQuery
    ) {
        this.initializeApp();
        this.authService.isSignIn();
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.splashScreen.hide();
            this.authService.getCognitoUserInfo().then();
        });
    }

    ngOnInit() {
        this.checkRouterSubscription = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                if (this.sideMenuService.isAdminPath(event.url)) {
                    this.sideMenu = this.sideMenuService.adminSideMenu;
                } else {
                    this.sideMenu = this.sideMenuService.userSideMenu;
                }
            }
        });
    }

    ngOnDestroy(): void {
        this.checkRouterSubscription.unsubscribe();
    }


    async signOut() {
        if (!await this.authService.signOut()) {
            alert('Sign out is failed.');
        }
    }
}
