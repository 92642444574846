import {Injectable} from '@angular/core';
import {Auth} from 'aws-amplify';
import {CognitoHostedUIIdentityProvider} from '@aws-amplify/auth';
import {SignInForm, SignUpForm} from '../../interfaces/form';


import aws_exports from '../../../aws-exports';
import {AngularFireAuth} from '@angular/fire/auth';
import firebase from 'firebase';
import {CognitoIdentityCredentials} from 'aws-sdk';
import {MenuController} from '@ionic/angular';
import {SharedService} from './shared.service';
import {from, Observable, of} from 'rxjs';
import {catchError, map, take, tap} from 'rxjs/operators';
import {UserQuery, UserService} from '../../state/userState';
import {AppGlobalConfig} from '../../app.config';
import {resetStores} from '@datorama/akita';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    userState$ = this.userStateQuery.userState$;


    constructor(private afAuth: AngularFireAuth,
                private menuController: MenuController,
                private sharedService: SharedService,
                private userStateService: UserService,
                private userStateQuery: UserQuery) {
        this.isSignIn();
    }

    async getCognitoUserCred() {
        try {
            return await Auth.currentUserCredentials();
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    async getCognitoUserInfo() {
        try {
            return await Auth.currentAuthenticatedUser();
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    async signInWithGoogle() {
        try {
            await Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google});
        } catch (e) {
            console.log(e);
        }
    }

    async signInWithFacebook() {
        try {
            await Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Facebook});
        } catch (e) {
            console.log(e);
        }
    }


    async signInWithApple() {
        try {
            await Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Apple});
        } catch (e) {
            console.log(e);
        }
    }

    async signInWithLine(provider) {
        try {
            await Auth.federatedSignIn({provider});
        } catch (e) {
            console.log(e);
        }
    }

    async signInWithTwitter() {
        try {
            const res: any = await this.afAuth.signInWithPopup(new firebase.auth.TwitterAuthProvider());
            await this.signInCogniteIdentityWithTwitter(res.credential.accessToken, res.credential.secret);
            // const tokenRes = await res.user.getIdTokenResult();
            // console.log(jwtDecode(tokenRes.token));
            // await Auth.federatedSignIn('newordinary-mcn.firebaseapp.com',
            //     {token: tokenRes.token, expires_at: Date.parse(tokenRes.expirationTime)},
            //     {email: 'kenta@qict.co.jp', name: 'kenta'});
            return true;
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    private async signInCogniteIdentityWithTwitter(accessToken: string, secret: string) {
        const cogniteTwitterSignIn = new CognitoIdentityCredentials({
            IdentityPoolId: aws_exports.aws_cognito_identity_pool_id,
            Logins: {
                'api.twitter.com': `${accessToken};${secret}`
            }
        });
        console.log(cogniteTwitterSignIn);
        await cogniteTwitterSignIn.getPromise();
        console.log('AfterTwitterLogin', cogniteTwitterSignIn.data);
        Auth.currentCredentials().then(cred => {
            console.log(cred);
        });
    }


    /**
     * 新規登録フォームの情報を受取新規登録を行う
     * @param signUpUserData
     */
    async signUp(signUpUserData: SignUpForm) {
        try {
            const {user} = await Auth.signUp({
                username: signUpUserData.userEmail,
                password: signUpUserData.userPassword,
                attributes: {
                    nickname: signUpUserData.userFullName
                }
            });
            console.log(user);
            return true;
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    /**
     * ログインフォームの情報を受取ログインを行う
     * @param signInUserData
     */
    async signIn(signInUserData: SignInForm) {
        try {
            const {user} = await Auth.signIn({
                username: signInUserData.userName,
                password: signInUserData.userPassword
            });
            console.log(user);
            return true;
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    async signOut() {
        try {
            await this.afAuth.signOut();
            await Auth.signOut({global: true});
            this.menuController.enable(false);
            this.sharedService.redirectTo('/auth/login');
            // akitaのストアをクリアする
            resetStores();
            return true;
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    /**
     * ページ遷移事に呼び出されるようにしている
     */
    isSignIn(): Observable<boolean | any> {
        return from(Auth.currentAuthenticatedUser()).pipe(
            map(user => {
                return user;
            }),
            catchError(error => {
                console.log('isSignIn():', error);
                this.sharedService.onError({errorType: 'InvalidError', logging: false, payLoad: error});
                return of(false);
            }),
            tap(async user => {
                // サインインのチェック後にユーザーステートにコグナイトのセッション情報を格納して取り回せるようにする
                if (user) {
                    const {...userState} = await this.userState$.pipe(take(1)).toPromise();
                    userState.isLogin = true;
                    userState.userEmail = user.attributes.email;
                    userState.userName = user.attributes.username;
                    userState.cognitoUser = Object.create(user);
                    await this.userStateService.updateUserState(userState);
                } else {
                    const {...userState} = await this.userState$.pipe(take(1)).toPromise();
                    userState.isLogin = false;
                    userState.cognitoUser = null;
                    await this.userStateService.updateUserState(userState);
                }
            })
        );
    }

    /**
     * 管理者画面へログインできるユーザを絞り込む
     * @param roleArray
     */
    checkRole(roleArray: string[]) {
        return roleArray.filter(role =>
            role.includes(AppGlobalConfig.USER_ROLE_LIST.SYSTEM_ADMIN_USER_GROUP_NAME) ||
            role.includes(AppGlobalConfig.USER_ROLE_LIST.EVENT_ADMIN_USER_GROUP_NAME));
    }

}
