import {Injectable} from '@angular/core';
import {UserStore} from './user.store';
import {STORE_ENTITY_ID} from '../state.config';
import {User} from './user.model';
import {Storage} from '@ionic/storage';
import {UserQuery} from './user.query';


@Injectable({providedIn: 'root'})
export class UserService {

    constructor(private userStore: UserStore, private userQuery: UserQuery, private storage: Storage) {
        // this.initUserState();
    }

    private async initUserState() {
        this.userStore.upsert(STORE_ENTITY_ID.USER_STATE, {
            isLogin: false,
            isUserFavoriteQuestion: false,
            cognitoUser: null
        });

        const res = await this.loadUserStateFromStorage();
        console.log(res);
        if (res) {
            this.userStore.upsert(STORE_ENTITY_ID.USER_STATE, res);
        }
    }

    /**
     * ローカルストレージから前の状態を取得
     * @private
     */
    private async loadUserStateFromStorage() {
        const res = await this.storage.get(STORE_ENTITY_ID.USER_STATE);
        if (!res) {
            return null;
        }
        return JSON.parse(res);
    }

    private async saveUserStateToStorage() {
        const res = this.userQuery.getEntity(STORE_ENTITY_ID.USER_STATE);
        await this.storage.set(STORE_ENTITY_ID.USER_STATE, JSON.stringify(res));
    }

    async updateUserState(userState: Pick<User, 'isLogin' | 'cognitoUser' | 'isUserFavoriteQuestion' | 'userName' | 'userIconUrl' | 'userEmail' | 'interestedContent'>) {
        await this.userStore.upsert(STORE_ENTITY_ID.USER_STATE, userState);
    }


}
