import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {UiState, UiStore} from './ui.store';
import {HeadUtilState} from '../../interfaces/uistate';
import {distinctUntilChanged, map, tap} from 'rxjs/operators';
import {AgmCameraState, AgmCurrentLocationState, AgmNavigationState} from '../../interfaces/agm/agm-state';
import {STORE_ENTITY_ID} from '../state.config';
import {AgmLocationState} from '../agmLocationState';

@Injectable({providedIn: 'root'})
export class UiQuery extends QueryEntity<UiState> {
    // 各ページでストアをサブスクライブ出来るようにしておく
    HeadUtilState$ = this.selectEntity<HeadUtilState>(STORE_ENTITY_ID.HEAD_UTIL_STATE).pipe(map(ui => ui.headUtilSpaceState));
    /**
     * 検索結果に変更がかかったタイミングで発火
     */
    HeadUtilSearchBoxState$ = this.selectEntity<HeadUtilState>(STORE_ENTITY_ID.HEAD_UTIL_STATE).pipe(map(ui => ui.headUtilSpaceState.uiSearchBoxState), distinctUntilChanged(), tap(search => console.log(search)));
    AgmCameraState$ = this.selectEntity<AgmCameraState>(STORE_ENTITY_ID.AGM_CAMERA_STATE).pipe(map(camera => camera.agmCameraState));
    AgmUserMapState$ = this.selectEntity<AgmCameraState>(STORE_ENTITY_ID.AGM_USER_MAP_STATE).pipe(map(user => user.agmUserMapState.userMapState));
    AgmAdminMapState$ = this.selectEntity<AgmCameraState>(STORE_ENTITY_ID.AGM_ADMIN_MAP_STATE).pipe(map(admin => admin.agmAdminMapState.adminMapState));
    AgmUserCurrentLocationState$ = this.selectEntity<AgmCurrentLocationState>(STORE_ENTITY_ID.AGM_CURRENT_LOCATION_STATE).pipe(map(clocation => clocation?.agmCurrentLocationState));
    AgmRouteTargetState$ = this.selectEntity<AgmLocationState[]>(STORE_ENTITY_ID.AGM_ROUTE_TARGET_STATE).pipe(map(target => target.agmRouteTargetState));
    AgmNavigationState$ = this.selectEntity<AgmNavigationState>(STORE_ENTITY_ID.AGM_NAVIGATION_STATE).pipe(map(navi => navi?.agmNavigationState));


    constructor(protected store: UiStore) {
        super(store);
    }

    getHeadUtilState() {
        return this.getEntity('HeadUtilSpaceState').headUtilSpaceState;
    }

    getAgmNaviState() {
        return this.getEntity(STORE_ENTITY_ID.AGM_NAVIGATION_STATE).agmNavigationState;
    }

    getAgmRouteTargetState() {
        return this.getEntity(STORE_ENTITY_ID.AGM_ROUTE_TARGET_STATE).agmRouteTargetState;
    }

}
