import {CognitoUser} from 'amazon-cognito-identity-js';

export interface User {
    id: string;
    isLogin?: boolean;
    cognitoUser?: CognitoUser;
    userIconUrl?: string;
    userName?: string;
    userEmail?: string;
    isUserFavoriteQuestion?: boolean;
    interestedContent?: string[];
}

export function createUser(params: Partial<User>) {
    return {} as User;
}
