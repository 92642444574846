/**
 * 管理者画面で使用するメッセージ
 */
const AdminPageMessage = {
    SUBMIT_CIRCLE_AREA: 'エリアの登録を行います。',
    SUBMIT_POLYGON_AREA: 'エリアの登録を行います'
};

/**
 * ユーザページで使用するメッセージ
 */
const UserPageMessage = {};

/**
 * システムからのメッセージ（エラーなど）
 */
const SystemMessage = {
    CONFIRM_CANCEL_TEXT: '中止'
};

export const AppMessageConfig = {
    AdminPageMessage,
    UserPageMessage,
    SystemMessage
};

