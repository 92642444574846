import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {PageNotFoundComponent} from './core/components/error/page-not-found/page-not-found.component';
import {AdminGuard} from './core/guards/admin.guard';
import {AuthGuard} from './core/guards/auth.guard';
import {UnauthGuard} from './core/guards/unauth.guard';
import {environment} from '../environments/environment';

const routes: Routes = [
    {
        path: 'app',
        loadChildren: () => import('./user/user.module').then(m => m.UserPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthPageModule),
        canActivate: [UnauthGuard]
    },
    {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminPageModule),
        canActivate: [AdminGuard]
    },
    // 開発中のみ有効なルート
    environment.production === true ? {path: 'develop', redirectTo: ''} : {
        path: 'develop',
        loadChildren: () => import('./develop/develop.module').then(m => m.DevelopPageModule)
    },
    {
        path: '',
        redirectTo: 'app',
        pathMatch: 'full'
    },
    {
        path: '**',
        component: PageNotFoundComponent
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,
            scrollPositionRestoration: 'top',
            relativeLinkResolution: 'corrected'
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
