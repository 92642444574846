import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';
import {PageNotFoundComponent} from './components/error/page-not-found/page-not-found.component';
import {SidemenuComponent} from './components/sidemenu/sidemenu.component';
import {RouterModule} from '@angular/router';
import { ThumbnailPipe } from './pipes/thumbnail.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule
  ],
  declarations: [
    PageNotFoundComponent,
    SidemenuComponent,
    ThumbnailPipe,
  ],
  exports: [
    PageNotFoundComponent, 
    SidemenuComponent,
    ThumbnailPipe,
  ]
})
export class CoreModule {
}
