import {Injectable} from '@angular/core';
import {ErrorState} from '../../interfaces/system/error';

/**
 * エラーを解決するサービス
 */
@Injectable({
    providedIn: 'root'
})
export class ErrorService {

    constructor() {
    }

    /**
     * エラーを受け取る
     * @param errorState
     */
    onError(errorState: ErrorState) {
        if (errorState.errorType === 'InvalidError') {

        }

        if (errorState.errorType === 'SystemError') {
            console.log(errorState.payLoad);
        }

        if (errorState.errorType === 'RetryError') {

        }
    }
}
