import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {SharedService} from '../services/shared.service';
import {map} from 'rxjs/operators';
import {UserQuery} from '../../state/userState';

/**
 * ログインしていんたらログイン画面に入らせない。
 * ログインしていなかったらログイン画面へ戻す。
 */
@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(
        private authService: AuthService,
        private sharedService: SharedService,
        private userQuery: UserQuery
    ) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.authService.isSignIn().pipe(
            map(isUser => {
                if (isUser) {
                    const {...userState} = this.userQuery.getUserState();
                    if (!userState) {
                        // もしStoreの情報が無かったら
                        this.authService.signOut();
                        return false;
                    }

                    if (!userState.isUserFavoriteQuestion && !state.url.includes('question')) {
                        // 問題に答えていなかったら
                        this.sharedService.redirectTo('/app/question');
                        return false;
                    }

                    return true;
                } else {
                    this.sharedService.redirectTo('/auth/login');
                    return false;
                }
            }));
    }

}
