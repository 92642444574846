import {HeadUtilState} from '../../interfaces/uistate';
import {AgmCameraState, AgmCurrentLocationState, AgmLocationState, AgmMapState, AgmNavigationState} from '../../interfaces/agm/agm-state';

export interface Ui {
    id: string;
    headUtilSpaceState?: HeadUtilState;
    agmCameraState?: AgmCameraState;
    agmCurrentLocationState?: AgmCurrentLocationState;
    agmUserMapState?: Pick<AgmMapState, 'userMapState'>;
    agmAdminMapState?: Pick<AgmMapState, 'adminMapState'>;
    agmRouteTargetState?: AgmLocationState[];
    agmNavigationState?: AgmNavigationState;
}

export function createUi(params: Partial<Ui>) {
    return {} as Ui;
}
