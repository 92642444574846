import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {SharedService} from '../services/shared.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UnauthGuard implements CanActivate {
  constructor(
      private authService: AuthService,
      private sharedService: SharedService,
  ) {
  }

  canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.isSignIn().pipe(
        map(isUser => {
          if (isUser) {
            this.sharedService.redirectTo('/app');
            return false;
          } else {
            return true;
          }
        }));
  }

}
