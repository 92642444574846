import {AppGlobalConfigType, FormErrorMessages, FormSettings, MoveDistance, RoleList} from './app.config.types';
import {
    ArtCategory,
    AttainmentsCategory,
    CultureCategory,
    EntertainmentCategory,
    GourmetCategory,
    HealthCategory,
    OutdoorCategory,
    PlayCategory,
    RefreshCategory,
    SportCategory
} from './interfaces/system/category';

/**
 * AmplifyAdminUI上のユーザから同名のGroupを作成して、そこに含まれているユーザを管理者として機能するようにする
 */
const UserRole: RoleList = {
    SYSTEM_ADMIN_USER_GROUP_NAME: 'SystemAdminUsers',
    EVENT_ADMIN_USER_GROUP_NAME: 'EventAdminUsers'
};

/**
 * エラー時に表示するメッセージ
 */
const formErrorMessage: FormErrorMessages = {
    REQUIRED_ERROR: '必須入力です。',
    EMAIL_ERROR: 'Eメールの形式が正しくありません。',
    SIGNUP_PASSWORD_ERROR: 'パスワードは半角英数字大文字小文字1文字ずつ32文字まです。'
};

/**
 * フォームの設定
 */
const formSetting: FormSettings = {
    // 半角英数字大文字小文字一文字ずつ32文字まで
    SIGNUP_PASSWORD_PATTERN: /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]{8,32}$/,
    SIGNUP_PASSWORD_MINLENGTH: 8
};

/**
 * 1分あたりに進む距離(m)の設定
 * 現在地からの検索の際に円の半径として使用する
 * 歩きは分速80m
 * 車は分速 500m
 * 電車は分速1000m
 */
const moveDistanceList: MoveDistance = {
    WALK: 80,
    CAR: 500,
    TRANSIT: 1000
};

/**
 * マップに描画するエリアやサークルに設定する
 */
const mapDrawingSetting = {
    USER_CIRCLE: {
        strokeColor: 'darkkhaki',
        strokeOpacity: 0.7,
        strokeWeight: 1,
        fillColor: 'khaki',
        fillOpacity: 0.2
    },
    EVENT_POLYGON_AREA: {
        strokeColor: 'darkkhaki',
        strokeOpacity: 0.7,
        strokeWeight: 1,
        fillColor: 'khaki',
        fillOpacity: 0.2
    },
    EVENT_CIRCLE_AREA: {
        strokeColor: 'darkkhaki',
        strokeOpacity: 0.7,
        strokeWeight: 1,
        fillColor: 'khaki',
        fillOpacity: 0.2
    }
};

/**
 * 好みの設定画面で表示する好み情報設定
 */
const favoriteThingSetting = [
    {
        favoriteContent: {
            categoryName: 'エンタメを楽しむ',
            categoryDisplayName: 'エンタメ',
            categoryIconName: '1-favoriteIcon.jpg',
            categoryColor: '#7036BF',
            googleMapCategoryList: []
        } as EntertainmentCategory
    },
    {
        favoriteContent: {
            categoryName: '芸能を嗜む',
            categoryDisplayName: '芸能',
            categoryIconName: '2-favoriteIcon.jpg',
            categoryColor: '#4B99DD',
            googleMapCategoryList: []
        } as AttainmentsCategory
    },
    {
        favoriteContent: {
            categoryName: '芸術に触れる',
            categoryDisplayName: 'アート',
            categoryIconName: '3-favoriteIcon.jpg',
            categoryColor: '#833A12',
            googleMapCategoryList: []
        } as ArtCategory
    },
    {
        favoriteContent: {
            categoryName: 'グルメを楽しむ',
            categoryDisplayName: 'グルメ',
            categoryIconName: '4-favoriteIcon.jpg',
            categoryColor: '#FF88C3',
            googleMapCategoryList: []
        } as GourmetCategory
    },
    {
        favoriteContent: {
            categoryName: '自然に触れる',
            categoryDisplayName: 'アウトドア',
            categoryIconName: '5-favoriteIcon.jpg',
            categoryColor: '#00936B',
            googleMapCategoryList: []
        } as OutdoorCategory
    },
    {
        favoriteContent: {
            categoryName: '体を動かす',
            categoryDisplayName: 'スポーツ',
            categoryIconName: '6-favoriteIcon.jpg',
            categoryColor: '#BE0707',
            googleMapCategoryList: []
        } as SportCategory
    },
    {
        favoriteContent: {
            categoryName: '体を癒す',
            categoryDisplayName: 'リフレッシュ',
            categoryIconName: '7-favoriteIcon.jpg',
            categoryColor: '#FFCE00',
            googleMapCategoryList: []
        } as RefreshCategory
    },
    {
        favoriteContent: {
            categoryName: '思いっきり遊ぶ',
            categoryDisplayName: '遊ぶ',
            categoryIconName: '8-favoriteIcon.jpg',
            categoryColor: '#B91A81',
            googleMapCategoryList: []
        } as PlayCategory
    },
    {
        favoriteContent: {
            categoryName: '健康活動',
            categoryDisplayName: '健康',
            categoryIconName: '9-favoriteIcon.jpg',
            categoryColor: '#FF7474',
            googleMapCategoryList: []
        } as HealthCategory
    },
    {
        favoriteContent: {
            categoryName: 'カルチャーを体感する',
            categoryDisplayName: 'カルチャー',
            categoryIconName: '10-favoriteIcon.jpg',
            categoryColor: '#FF6200',
            googleMapCategoryList: []
        } as CultureCategory
    },
];

/**
 * プレイスサーチの設定
 */
const placeSearchSetting = {
    // 検索エリアの半径(m)
    areaRadius: 2500
};

/**
 * アプリ全体で使用する設定をまとめてexport
 */
export const AppGlobalConfig: AppGlobalConfigType = {
    FORM_SETTINGS: formSetting,
    FORM_ERROR_MESSAGE: formErrorMessage,
    USER_ROLE_LIST: UserRole,
    MOVE_DISTANCE_SETTING: moveDistanceList,
    MAP_DRAWING_SETTING: mapDrawingSetting,
    FAVORITE_THINGS_SETTING: favoriteThingSetting,
    PLACE_SEARCH_SETTING: placeSearchSetting
};

/**
 * GoogleMap関連
 */
/**
 * スタイルJSON
 */
export const MapStylesJson = [
    {
        featureType: 'administrative',
        elementType: 'geometry',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'administrative.neighborhood',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'poi',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'poi',
        elementType: 'labels.text',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'road',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'road',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'transit',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'water',
        elementType: 'labels.text',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    }
];

export const GoogleMapLibrary = ['places', 'drawing'];
export const MapTypeId = 'terrain';
export const GoogleMapCategory = {
    Entertainment: []
};

// PlaceApi
/**
 * PlaceApiの場所検索で返ってくる要素を設定
 */
export const PlaceApiSearchQueryResponseFields = ['name', 'geometry', 'place_id', 'icon', 'photos'];
/**
 * 予測を指定された国に制限します（ISO 3166-1 Alpha-2国コード、大文字と小文字は区別されません）。たとえば、'us'、'br'、または'au'。1つ、または最大5つの国コード文字列の配列を指定できます。
 */
export const PlaceApiAutoCompleatTargetCountry = ['jp'];
