import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';
import * as AWS from 'aws-sdk';
import {enableAkitaProdMode, persistState} from '@datorama/akita';
import {debounceTime} from 'rxjs/operators';

// AWS Config
Amplify.configure(aws_exports);
AWS.config.update({region: aws_exports.aws_project_region});


persistState({
    include: ['user', 'ui'],
    preStorageUpdateOperator: () => debounceTime(100)
});

if (environment.production) {
    enableProdMode();
    enableAkitaProdMode();

    // プロダクションモードではconsole.logを無効にする
    // Alertも無効にする
    if (window) {
        window.console.log = () => {
        };
        window.alert = () => {
        };
    }
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.log(err));
